// app.config

import {
  APP_ID,
  ApplicationConfig,
  FactoryProvider,
  importProvidersFrom,
  inject,
  LOCALE_ID,
  provideAppInitializer,
  TransferState,
  ValueProvider,
} from '@angular/core';
import {
  BrowserModule,
  provideClientHydration,
  withEventReplay,
  withHttpTransferCacheOptions,
} from '@angular/platform-browser';
import {
  API_BASE_URL,
  AppConfigService,
  configKey,
  FULL_STORAGE_URL,
  SiteSettingsApiClient,
  SiteTitleService,
  STORAGE_FILE_DOWNLOAD_URL_GETTER,
  STORAGE_FILE_URL_GETTER,
  STORAGE_URL,
  StorageFileUrlGetterFn,
} from '@math-site/services';
import { provideHttpClient } from '@angular/common/http';
import { provideAnimations } from '@angular/platform-browser/animations';

import { ErrorResponseService } from './pages/site/services/error-response.service';
import { AppRoutingModule } from './app-routing.module';

export function storageFileUrlGetterFactory(storageUrl: string): StorageFileUrlGetterFn {
  return (id) => `${storageUrl}${id}`;
}

export function storageFileDownloadUrlGetterFactory(storageUrl: string): StorageFileUrlGetterFn {
  return (id) => `${storageUrl}/file/download/${id}`;
}

export function fullStorageUrlFactory(storageUrl: string): string {
  return `${storageUrl}/file/get/`;
}

export function provideApiBaseUrl(): FactoryProvider {
  return {
    provide: API_BASE_URL,
    deps: [AppConfigService],
    useFactory: (cfg: AppConfigService) => cfg.get()?.api,
  };
}

export function provideStorageUrl(): FactoryProvider {
  return {
    provide: STORAGE_URL,
    deps: [AppConfigService],
    useFactory: (cfg: AppConfigService) => cfg.get()?.storageUrl,
  };
}

export function provideFullStorageUrl(): FactoryProvider {
  return {
    provide: FULL_STORAGE_URL,
    deps: [STORAGE_URL],
    useFactory: fullStorageUrlFactory,
  };
}

export function provideStorageFileUrlGetter(): FactoryProvider {
  return {
    provide: STORAGE_FILE_URL_GETTER,
    deps: [FULL_STORAGE_URL],
    useFactory: storageFileUrlGetterFactory,
  };
}

export function provideFileDownloadUrlGetter(): FactoryProvider {
  return {
    provide: STORAGE_FILE_DOWNLOAD_URL_GETTER,
    deps: [STORAGE_URL],
    useFactory: storageFileDownloadUrlGetterFactory,
  };
}

export function provideRuLocale(): ValueProvider {
  return {
    provide: LOCALE_ID,
    useValue: 'ru-RU',
  };
}

export function provideAppId(): ValueProvider {
  return { provide: APP_ID, useValue: 'math-ssr' };
}

export const appConfig: ApplicationConfig = {
  providers: [
    provideAppId(),
    importProvidersFrom(BrowserModule),
    AppConfigService,
    provideAppInitializer(() => {
      const cfg = inject(TransferState).get(configKey, undefined);

      return inject(AppConfigService).init$(cfg);
    }),
    provideApiBaseUrl(),
    provideStorageUrl(),
    provideFullStorageUrl(),
    provideStorageFileUrlGetter(),
    provideFileDownloadUrlGetter(),
    provideRuLocale(),
    provideClientHydration(
      withHttpTransferCacheOptions({ includePostRequests: false }),
      withEventReplay(),
    ),
    provideHttpClient(),
    provideAnimations(),

    SiteSettingsApiClient,
    SiteTitleService,
    ErrorResponseService,
    importProvidersFrom(AppRoutingModule),
  ],
};
