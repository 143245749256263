import { enableProdMode } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import localeRu from '@angular/common/locales/ru';
import { registerLocaleData } from '@angular/common';

import { environment } from './environments/environment';
import { AppComponent } from './app/app.component';
import { appConfig } from './app/app.config';

if (environment.production) {
  enableProdMode();
}

registerLocaleData(localeRu, 'ru-RU');

function bootstrap() {
  bootstrapApplication(AppComponent, appConfig)
    // eslint-disable-next-line no-console
    .catch((err) => console.error(err));
}

if (document.readyState === 'complete') {
  bootstrap();
} else {
  document.addEventListener('DOMContentLoaded', bootstrap);
}
